import * as React from "react"

import { SiHatenabookmark } from "react-icons/si";
import { SiTwitter } from "react-icons/si";
import { SiFacebook } from "react-icons/si";
import { SiPocket } from "react-icons/si";
import { SiLine } from "react-icons/si";

const Share = (props) =>{
    return(
        <div className="share-btns">
            <ul>
                <li><a href={`http://b.hatena.ne.jp/entry/s/hatena.pocopota.com/${props.id}`} target="_blank" rel="noreferrer" title="はてなブックマーク"><SiHatenabookmark/></a></li>
                <li><a href={`http://twitter.com/share?url=https://hatena.pocopota.com/${props.id}&text=${props.title}`} target="_blank" rel="noreferrer" title="Twitterでシェア"><SiTwitter/></a></li>
                <li><a href={`http://www.facebook.com/share.php?u=https://hatena.pocopota.com/${props.id}`} target="_blank" rel="noreferrer" title="Facebookでシェア"><SiFacebook/></a></li>
                <li><a href={`http://getpocket.com/edit?url=https://hatena.pocopota.com/${props.id}`} target="_blank" rel="noreferrer" title="Pocketに保存"><SiPocket/></a></li>
                <li><a href={`https://social-plugins.line.me/lineit/share?url=https://hatena.pocopota.com/${props.id}`} target="_blank" rel="noreferrer" title="LINEでシェア"><SiLine/></a></li>
            </ul>
        </div>
    )
}

export default Share