// src/pages/{microcmsBlogs.blogsId}.js
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Share from "../components/share"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import cheerio from "cheerio"
import hljs from "highlight.js"
import "highlight.js/styles/atom-one-dark.css"

import { BiCalendarCheck } from "react-icons/bi";
import { BiCalendarEdit } from "react-icons/bi";
import { BiPurchaseTag } from "react-icons/bi";

dayjs.extend(utc);
dayjs.extend(timezone);

const BlogPage = ({ data }) => {
  const contentBody = cheerio.load(data.microcmsBlogs.content);
  contentBody("pre code").each((_, elm) => {
    const result = hljs.highlightAuto(contentBody(elm).text());
    contentBody(elm).html(result.value);
    contentBody(elm).addClass("hljs");
  })
  return(
    <Layout>
      <SEO title={data.microcmsBlogs.title} eyecatch={data.microcmsBlogs.eyecatch.url} />
      <main className="article-main">
      <article>
          <div className="eyecatch">
            <img src={`${data.microcmsBlogs.eyecatch.url}`}></img>
          </div>
          <div className="article-article">
            <h1>{data.microcmsBlogs.title}</h1>
            <section className="tags">
                {data.microcmsBlogs.tag.map(tag => {
                  return(
                    // <div><BiPurchaseTag/><Link to={`/${tag}`}>{tag}</Link></div>
                    <div><BiPurchaseTag/>{tag}</div>
                  )
                })}
            </section>
            <section className="dates">
              <div><BiCalendarCheck/>{dayjs.utc(data.microcmsBlogs.publishedAt).tz('Asia/Tokyo').format('YYYY-MM-DD')}公開</div>
              {dayjs.utc(data.microcmsBlogs.publishedAt).tz('Asia/Tokyo').format('YYYY-MM-DD') !== dayjs.utc(data.microcmsBlogs.updatedAt).tz('Asia/Tokyo').format('YYYY-MM-DD') && (<div><BiCalendarEdit/>{dayjs.utc(data.microcmsBlogs.updatedAt).tz('Asia/Tokyo').format('YYYY-MM-DD')}更新</div>)}
            </section>
            <section className="sns-share">
              <Share
                id={data.microcmsBlogs.blogsId}
                title={data.microcmsBlogs.title}
              />
            </section>
            <section className="article-content"
                dangerouslySetInnerHTML={{
                __html: contentBody.html()
                }}
            />
          </div>
      </article>
      </main>
    </Layout>
  )
}


export default BlogPage

export const query = graphql`
  query($id: String!) {
    microcmsBlogs(id: { eq: $id }) {
        title
        blogsId
        updatedAt
        publishedAt
        content
        tag
        eyecatch{
          url
        }
    }
  }
`
